exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-carrito-js": () => import("./../../../src/pages/carrito.js" /* webpackChunkName: "component---src-pages-carrito-js" */),
  "component---src-pages-catalogo-contentful-libro-title-js": () => import("./../../../src/pages/catalogo/{ContentfulLibro.title}.js" /* webpackChunkName: "component---src-pages-catalogo-contentful-libro-title-js" */),
  "component---src-pages-catalogo-index-js": () => import("./../../../src/pages/catalogo/index.js" /* webpackChunkName: "component---src-pages-catalogo-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-resultado-[encoded]-js": () => import("./../../../src/pages/resultado/[encoded].js" /* webpackChunkName: "component---src-pages-resultado-[encoded]-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

